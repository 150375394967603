import React, { useState } from "react";

const PhotoPage = () => {
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  const [comment, setComment] = useState("");

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSubmitComment = () => {
    // Here you can implement logic to handle submitting the comment
    console.log("Comment submitted:", comment);
    // For this example, let's clear the comment input field after submitting
    setComment("");
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div className="photo-container">
        <img
          src={`${apiUrl}/pukuli.jpg`}
          alt="Your Photo"
          style={{ maxWidth: "100%", height: "auto" }}
        />
      </div>
      <div
        className="comment-box"
        style={{
          width: "100%",
          maxWidth: "600px",
          marginTop: "20px",
          padding: "10px",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "200px",
            boxSizing: "border-box",
            padding: "10px",
            border: "2px solid black",
            borderRadius: "5px",
            resize: "vertical",
          }}
        >
          {" "}
          Khabar Nagari Lapata vayeu vane yesma samjinxu timlai
        </div>
      </div>
    </div>
  );
};

export default PhotoPage;
