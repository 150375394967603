import React from 'react'
import "../Global.css";

function Blured() {
  return (
    <>
    <div className='blurred'></div>
   
    </>
  )
}

export default Blured